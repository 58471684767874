<template>
  <CRow>
    <CCol col="12" lg="12">
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>
        {{ err_msg }}
      </CAlert>
      <CCard>
        <CCardHeader>
          <strong>{{ $lang.company.crud.view }}</strong>
        </CCardHeader>
        <CCardBody>
          <table class="table table-bordered">
            <tr>
              <th>{{ $lang.company.form.company_name }}</th>
              <td>{{ company.company_name }}</td>
            </tr>
            <tr>
              <th>{{ $lang.company.form.email }}</th>
              <td>{{ company.email }}</td>
            </tr>
            <tr>
              <th>{{ $lang.company.form.address }}</th>
              <td>{{ company.address }}</td>
            </tr>
            <tr>
              <th>{{ $lang.company.form.contact_no }}</th>
              <td>{{ company.mobile }}</td>
            </tr>
            <tr>
              <th>{{ $lang.company.form.cp_name }}</th>
              <td>{{ company.cp_name }}</td>
            </tr>
            <tr>
              <th>{{ $lang.company.form.cp_contact_no }}</th>
              <td>{{ company.cpMobile }}</td>
            </tr>
            <tr>
              <th>{{ $lang.company.form.cp_email }}</th>
              <td>{{ company.cp_email }}</td>
            </tr>
          </table>
        </CCardBody>
        <CCardFooter>
          <CButton color="danger" size="sm" @click="cancel">
            <CIcon name="cil-ban"/>
            {{ $lang.buttons.general.crud.cancel }}
          </CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {company, users} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import VueToasted from "vue-toasted";
import moment from "moment";
import VueTelInput from "vue-tel-input-fix";
import "vue-tel-input-fix/dist/vue-tel-input.css";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueToasted);
Vue.use(VueTelInput);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);

export default {
  name: "CompanyCreate",
  mixins: [Mixin],
  data() {
    return {
      bindProps: {
        mode: "international",
        placeholder: "Contact No.",
        defaultCountry: "IN",
        dropdownOptions: {
          showFlags: true,
        },
        validCharactersOnly: true,
      },
      submitted: false,
      amountField: "",
      persontageField: "",
      userField: "",
      companyField: "",
      err_msg: "",
      dismissCountDown: 0,
      self: this,
      module: company,
      moduleUser: users,
      userOptions: [],
      editId: this.$route.params.id,
      company: {
        company_name: "",
        email: "",
        address: "",
        cp_email: "",
        cpCountryCode: "",
        cpMobile: "",
        cp_name: "",
        start_date: "",
        end_date: "",
        countryCode: "",
        mobile: "",
      },
    };
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    self.dismissCountDown = 0;
    axios
        .get(this.viewUrlApi(this.module, self.editId))
        .then((response) => {
          let company = response.data.data;
          self.userField = true;
          self.companyField = false;
          self.company.user = {};
          self.company.company_name = company.companyName;
          self.company.email = company.email;
          self.company.address = company.address;
          self.company.mobile = company.contactNoCode + " " + company.contactNo;
          self.company.cpMobile =
              company.cpContactNoCode + " " + company.cpContactNo;
          self.company.cp_email = company.cpEmail;
          self.company.cp_name = company.cpName;
          store.commit("showLoader", false); // Loader Off
        })
        .catch(function () {
          store.commit("showLoader", false); // Loader Off
        });
  },
  methods: {
    getHumanDate: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    sendAlert() {
      this.$root.$emit("alert", "test1", "test2");
    },
    cancel() {
      this.usersOpened
          ? this.$router.go(-1)
          : this.$router.push({path: "/company"});
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
